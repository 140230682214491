html, body, #root, #App-container {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
}

.widget iframe {
  width: 100%;
  height: 100%;
  min-height: 450px;
}

.sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
}